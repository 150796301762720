













































import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component } from 'vue-property-decorator'

import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import { tableFooterOptions } from '@/utils/constants'
import MasterDepositoryModule from '@/store/modules/master/depository'
import { CourseShortResource, IMasterDepositoryFilter, IMasterDepositoryFilterType } from '@/store/types'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'

@Component({
  components: {
    Select,
    TableFooter,
    TextInput,
  },
})
export default class MasterDepositoryCoursesTable extends NotifyMixin {
  private footerOptions = tableFooterOptions

  private get headers () {
    return [
      { sortable: false, text: '#', value: 'id' },
      { text: 'Курс', value: 'title' },
      { text: 'Предмет', value: 'subject' },
      { text: 'Полезные материалы', value: 'usefulMaterials' },
      { text: 'Учебные файлы', value: 'trainingFiles' },
    ]
  }

  private get subjects () {
    return DictionaryModule.subjects
  }

  private get courses () {
    return MasterDepositoryModule.courses.data
  }

  private get pagination () {
    return MasterDepositoryModule.courses.meta
  }

  private get filter () {
    return MasterDepositoryModule.coursesFilter
  }

  private set filter (filter: IMasterDepositoryFilter) {
    if (!isEqual(filter, this.filter) || this.courses.length === 0) {
      MasterDepositoryModule.setCoursesFilter(filter)
      this.fetchCourses()
    }
  }

  @Bind
  @Debounce(300)
  private fetchCourses () {
    MasterDepositoryModule.fetchCourses()
      .catch(this.notifyError)
  }

  private handleFilter (field: IMasterDepositoryFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }


  private handleRowClick (course: CourseShortResource) {
    this.$router.push({ name: 'master.depository.item', params: { courseID: course.id.toString() } })
      .catch(() => {return})
  }
}
