var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filter mb-6"},[_c('Select',{staticClass:"filter__col-4",attrs:{"options":_vm.subjects,"placeholder":"Выберите предмет","label":"Предмет","dense":"","outlined":"","rounded":"","clearable":true},on:{"input":function (value) { return _vm.handleFilter('subjectId', value); }},model:{value:(_vm.filter.subjectId),callback:function ($$v) {_vm.$set(_vm.filter, "subjectId", $$v)},expression:"filter.subjectId"}}),_c('TextInput',{staticClass:"filter__col-4",attrs:{"placeholder":"Курс","label":"Поиск","append-icon":"$search","dense":"","outlined":"","rounded":"","clearable":true},on:{"input":function (value) { return _vm.handleFilter('query', value); }},model:{value:(_vm.filter.query),callback:function ($$v) {_vm.$set(_vm.filter, "query", $$v)},expression:"filter.query"}})],1),_c('VDataTable',{staticClass:"data-table_clickable",attrs:{"headers":_vm.headers,"items":_vm.courses,"options":_vm.filter,"server-items-length":_vm.pagination.total,"footer-props":_vm.footerOptions,"hide-default-footer":"","disable-sort":true},on:{"update:options":function($event){_vm.filter=$event},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.id))])]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"accent--text"},[_vm._v(_vm._s(item.subject))])]}},{key:"item.usefulMaterials",fn:function(ref){
var item = ref.item;
return [_c('span',{class:!item.usefulMaterials && 'grey--text'},[_vm._v(_vm._s(item.usefulMaterials))])]}},{key:"item.trainingFiles",fn:function(ref){
var item = ref.item;
return [_c('span',{class:!item.trainingFiles && 'grey--text'},[_vm._v(_vm._s(item.trainingFiles))])]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',{attrs:{"props":props,"filter":_vm.filter},on:{"update:filter":function($event){_vm.filter=$event}}})]}},{key:"no-data",fn:function(){return [_c('NoDataFound',{attrs:{"skin":"grey"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }