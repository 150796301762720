








import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

import MasterDepositoryCoursesTable from '@/components/tables/MasterDepositoryCoursesTable.vue'

@Component({
  components: {
    MasterDepositoryCoursesTable,
  },
})
export default class Depository extends Vue {


  private metaInfo (): MetaInfo {
    return {
      title: 'Банк заданий',
    }
  }
}
